
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import {getModule} from 'vuex-module-decorators';
    import {namespace} from 'vuex-class';
    import {Prop, Watch} from 'vue-property-decorator';

    import 'firebase/auth';

    import {formHelper} from '@/helpers';
    import {IAccount, IClient} from '@/types';
    import {AccountModule, SnackbarModule} from '@/store/modules';
    import PhoneInput from '@/components/forms/PhoneInput.vue';
    import PlaceInput from '@/components/forms/PlaceInput.vue';
    import RecaptchaComponent from '@/components/RecaptchaComponent.vue';

    const accountNamespace = namespace('account');

    @Component<RegisterFormCard>({
        components: {
            PlaceInput,
            RecaptchaComponent,
            PhoneInput
        },
    })
    export default class RegisterFormCard extends Vue {
        public accountFormValid = false;
        public submittingAccount = false;
        public email: string = '';
        public emailConfirmation: string = '';
        public passwordConfirmation: string = '';
        public password: string = '';
        public acceptTerms = false;
        public optinInsurance: string|undefined = '';
        public showPassword: boolean = false;
        public showPasswordConfirm: boolean = false;

        public detailsFormValid = false;
        public submittingDetails = false;
        public firstName: string = '';
        public lastName: string = '';
        public cellPhone: string = '';

        public validateFormValid = false;
        public resendingCode = false;
        public verifyingCode = false;
        public code: string|null = null;

        public emailExistsDialog = false;

        public emailRules = formHelper.getEmailsRules();
        public firstNameRules = formHelper.getFirstNameRules();
        public lastNameRules = formHelper.getLastNameRules();
        public termsRules = formHelper.getTermsRules();
        public codeRules = formHelper.getValidationCodeRules();

        public passwordRules = [
            (v: string) => (!v || /^(?=.*[A-Za-z])(?=.*\d)(?=.*[!@#$%^&*().,;:\/?^"'\-+])[A-Za-z\d!@#$%^&*().,;:\/?^"'\-+]{12,}$/.test(v)) || `Le mot de passe doit contenir au moins 12 caractères, au moins un chiffre et un caractère spécial !@#$%^&*().,;:?^"+' minimum.`
        ];

        @Prop({
            type: String,
            required: false,
        })
        public initialEmail!: string;

        @accountNamespace.Action('clearStatus')
        public clearStatus!: () => void;

        @accountNamespace.Getter('loggedAccount')
        public loggedAccount!: IAccount|null;

        @accountNamespace.Getter('loggedClient')
        public loggedClient!: IClient;

        get step() {
            if (this.loggedClient) {
              return 'pending_validation';
            } else if (this.loggedAccount) {
              return 'pending_details';
            }

            return 'account';
        }

        public passwordConfirmationRules = [
            (v: string) => !!v || 'Veuillez confirmer votre mot de passe',
            this.validatePasswordConfirmation,
        ]; 

        public validatePasswordConfirmation(v: string) {
            return v === this.password || 'Les mots de passe doivent être identiques';
        }

        public checkPasswordConfirmation() {
            if (this.passwordConfirmation) {
                (this.$refs.passwordConfirmation as Record<string, any>).validate();
            }
        }

        public emailConfirmationRules = [
            (v: string) => !!v || 'Veuillez confirmer votre e-mail',
            this.validateEmailConfirmation,
        ];

        public validateEmailConfirmation(v: string) {
            return v === this.email || 'Les adresses e-mail doivent être identiques';
        }

        public checkEmailConfirmation() {
            if (this.emailConfirmation) {
                (this.$refs.emailConfirmation as Record<string, any>).validate();
            }
        }

        @Watch('loggedClient', {immediate: true})
        public onLoggedClientChanged() {
            if (this.loggedClient) {
                this.cellPhone = this.loggedClient.cell_phone;
            }
        }

        public submitAccount() {
            this.submittingAccount = true;
            getModule(AccountModule, this.$store)
                .register({
                    email: this.email,
                    password_raw: this.password,
                    accept_terms: this.acceptTerms,
                    type: 'client',
                })
                .catch((error) => {
                    if (error.response) {
                        if (error.response.status === 409) {
                            this.emailExistsDialog = true;
                        }
                    }
                })
                .finally(() => {
                    this.submittingAccount = false;
                    this.clearStatus();
                })
            ;
        }

        public submitDetails() {
            this.submittingDetails = true;
            (this.$refs.recaptchaVerifier as RecaptchaComponent)
                .renderAndVerifyRecaptcha()
                .then((recaptchaToken: string) => {
                    getModule(AccountModule, this.$store)
                        .createClient({
                            client: {
                                account_id: (this.loggedAccount as IAccount).id,
                                first_name: this.firstName,
                                last_name: this.lastName,
                                cell_phone: this.cellPhone,
                                has_optin_insurance: this.optinInsurance ? 'enabled' : 'disabled',
                            },
                            recaptcha_token: recaptchaToken,
                        })
                        .catch((error) => {
                            getModule(SnackbarModule, this.$store)
                                .display({
                                    message: 'Une erreur est survenue. Veuillez réessayer ulterieurement.',
                                    position: 'top',
                                    color: 'error',
                                })
                            ;
                        })
                        .finally(() => {
                            this.submittingDetails = false;
                            this.clearStatus();
                        })
                    ;
                })
            ;
        }

        public resendCode() {
            this.resendingCode = true;

            (this.$refs.recaptchaVerifier as RecaptchaComponent)
                .renderAndVerifyRecaptcha()
                .then((recaptchaToken: string) => {
                    getModule(AccountModule, this.$store)
                        .resendCode(recaptchaToken)
                        .then(() => {
                            getModule(SnackbarModule, this.$store)
                                .display({
                                    message: 'Votre code de confirmation a bien été envoyé',
                                    position: 'top',
                                    color: 'success',
                                })
                            ;
                        })
                        .catch(() => {
                            getModule(SnackbarModule, this.$store)
                                .display({
                                    message: 'Une erreur est survenue, veuillez réessayer ulterieurement',
                                    position: 'top',
                                    color: 'error',
                                })
                            ;
                        })
                        .finally(() => this.resendingCode = false)
                    ;
                })
            ;
        }

        public validatePhone() {
            this.verifyingCode = true;

            getModule(AccountModule, this.$store)
                .verifyPhone(this.code as string)
                .then(() => {
                    getModule(SnackbarModule, this.$store)
                        .display({
                            message: 'Merci, votre téléphone a bien été validé !',
                            position: 'top',
                            color: 'success',
                        })
                    ;
                    this.$emit('registered');
                })
                .catch(() => {
                    getModule(SnackbarModule, this.$store)
                        .display({
                            message: 'Code incorrect, veuillez réessayer',
                            position: 'top',
                            color: 'error',
                        })
                    ;
                })
                .finally(() => this.verifyingCode = false)
            ;
        }

        public mounted() {
            if (this.initialEmail) {
                this.email = this.initialEmail;
            }
        }
    }
